import * as React from 'react';
import { Component } from 'react';
import Layout from "../components/layout"

const NotFound = () => {
    return (
        <Layout>
            <h1>Forbidden 303!</h1>
        </Layout>
    )
}

export default NotFound